<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover" src="@/assets/rapid-staff-image2.jpg" alt="Equipo de Rapid Sale USA">
      <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <!-- Añadir el estilo del cuadro negro aquí -->
      <div class="bg-black bg-opacity-82 rounded-lg p-6">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">Sobre Nosotros</h1>

        <h2 class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold tracking-tight text-white">Llevando 15 Años de Experiencia a los EE. UU.</h2>

        <p class="mt-6 max-w-3xl text-xl text-white">
          En Rapid Sale USA, su tranquilidad es nuestra principal prioridad. Con más de 15 años de experiencia como compradores principales de propiedades en efectivo en el Reino Unido, estamos emocionados de expandir nuestros servicios a los Estados Unidos, ahora basados en Miami, Florida. Estamos comprometidos a ofrecer un servicio que no solo sea profesional y discreto, sino también amigable y comprensivo.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          A lo largo de nuestro viaje, nos hemos dedicado a ofrecer soluciones personalizadas a los propietarios, ayudándolos a vender sus casas rápidamente y con el mínimo estrés. Ya sea enfrentando demandas financieras urgentes, lidiando con avisos de ejecución hipotecaria, o simplemente queriendo una venta rápida para evitar las complejidades del mercado tradicional, lo hemos logrado todo. Nuestro compromiso con la excelencia se refleja en los comentarios positivos de nuestros clientes.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          Al llevar nuestro código de ética y un servicio al cliente excepcional a los Estados Unidos, nuestro objetivo es revolucionar el mercado inmobiliario estadounidense y ayudar a las personas a vender rápido con poco estrés. Cada situación de los propietarios es única, y lo honramos yendo más allá de lo esperado. ¿Necesita ayuda con la mudanza o almacenamiento? ¿Busca consejo sobre los siguientes pasos? Estamos aquí en cada paso del camino, asegurando que su transición de propiedad sea lo más libre de estrés posible. En Rapid Sale USA, no solo manejamos transacciones; transformamos mudanzas desafiantes en nuevos comienzos positivos.
        </p>
        
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/es/')">
          Obtenga una oferta en efectivo gratuita <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>



<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

export default {
  name: 'AboutUsEs',
  metaInfo() {
    return {
      title: 'Sobre Nosotros | Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Bienvenido a Rapid Sale, los principales compradores de propiedades en efectivo de EE. UU.',
        },
        {
          name: 'keywords',
          content: 'venta rápida, rapidsale, oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.com/es/about-us' }
      ]
    };
  },
};
</script>


  

  
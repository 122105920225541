<template>
  <div class="container mx-auto mt-0 p-4 bg-gray-100 rounded-md shadow-lg w-full max-w-xs sm:max-w-sm">
    <div class="bg-gray-100 p-4 rounded-md">
      <h2 class="text-xl sm:text-2xl font-extrabold mb-1">Great News!</h2>
      <p class="text-gray-800 text-sm md:text-base mb-2">
        We'd like to make a cash offer on your property.
        Please enter your details below.
      </p>
      <form id="request-cash-offer" @submit.prevent="submitForm">
        <!-- First Name -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="firstName"
            type="text"
            v-model="firstName"
            placeholder="First Name"
            @blur="validateFirstName"
          />
          <p class="text-red-500 text-xs italic" v-if="firstNameError">{{ firstNameError }}</p>
        </div>

        <!-- Last Name -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="lastName"
            type="text"
            v-model="lastName"
            placeholder="Last Name"
            @blur="validateLastName"
          />
          <p class="text-red-500 text-xs italic" v-if="lastNameError">{{ lastNameError }}</p>
        </div>

        <!-- Email -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="email"
            type="text"
            v-model="email"
            placeholder="Email"
            @blur="validateEmail"
          />
          <p class="text-red-500 text-xs italic" v-if="emailError">{{ emailError }}</p>
        </div>

        <!-- Phone -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="phone"
            type="text"
            v-model="phone"
            placeholder="Contact Number"
            @blur="validatePhone"
          />
          <p class="block text-xs font-medium text-gray-500 small-device-text pt-1">* We need a valid number to make an offer.</p>
          <p class="text-red-500 text-xs italic" v-if="phoneError">{{ phoneError }}</p>
        </div>

        <!-- Privacy Policy Checkbox -->
        <div class="mb-1">
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              class="form-checkbox text-green-500"
              v-model="privacyPolicy"
            />
            <span class="ml-2 text-xs">I agree to the <a href="/privacy-policy" class="text-blue-500" target="_blank">Privacy Policy</a>.</span>
          </label>
          <p class="text-red-500 text-xs italic" v-if="privacyPolicyError">{{ privacyPolicyError }}</p>
        </div>

        <!-- Consent to BMV Checkbox -->
        <div class="mb-1">
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              class="form-checkbox text-green-500"
              v-model="consentToBMV"
            />
            <span class="ml-2 text-xs">I consent to a Below Market Value offer.</span>
          </label>
          <p class="text-red-500 text-xs italic" v-if="consentToBMVError">{{ consentToBMVError }}</p>
        </div>

        <!-- SMS Consent Checkbox -->
          <div class="mb-1">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox text-green-500"
                v-model="smsConsent"
              />
              <span class="ml-2 text-xs">
                I agree to receive text messages from Rapid Sale regarding property cash offers. Message frequency may vary. Reply STOP to unsubscribe.
              </span>
            </label>
            <p class="text-red-500 text-xs italic" v-if="smsConsentError">{{ smsConsentError }}</p>
          </div>

          <!-- Email Consent Checkbox -->
          <div class="mb-1">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox text-green-500"
                v-model="emailConsent"
              />
              <span class="ml-2 text-xs">
                I agree to receive marketing emails from Rapid Sale regarding property cash offers. I can unsubscribe at any time.
              </span>
            </label>
            <p class="text-red-500 text-xs italic" v-if="emailConsentError">{{ emailConsentError }}</p>
          </div>


        <!-- Submit Button -->
        <div class="mb-4">
          <div class="flex items-center justify-between">
            <button
              class="border-b-4 border-green-800 mt-2 text-sm sm:text-lg block w-full items-center px-8 py-3 bg-green-600 text-white rounded-2xl font-bold hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              type="submit">
              Request Free Cash Offer →
            </button>
          </div>
        </div>
      </form>
      <div class="mt-2 flex justify-center">
        <p class="text-gray-500 flex items-center">
          <i class="text-green-600 fas fa-shield-alt mr-1 text-sm items-center"></i>
          Safe, secure &amp; confidential
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    addressData: {
      type: Object,
      default: () => ({}),
    },
    gclid: {
      type: String,
      default: '',
    },
    quizData: {
      type: Object,
      default: () => ({}),
    },
    lpOfferId: {
      type: String,
      default: '21' // Default value if not provided
    },
    lpCampaignId: {
      type: String,
      default: '67337b507f868' // Default value if not provided
    },
    utmCampaign: {
      type: String,
      default: '',
    },
    utmSource: {
      type: String,
      default: '',
    },
    utmMedium: {
      type: String,
      default: '',
    },
    utmKeyword: {
      type: String,
      default: '',
    },
    utmMatch: {
      type: String,
      default: '',
    },
    utmDevice: {
      type: String,
      default: '',
    },
  },
  name: "ContactForm",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      messageError: "",
      privacyPolicy: false,
      privacyPolicyError: "",
      consentToBMV: false, // BMV checkbox state
      consentToBMVError: '', // BMV error property
      smsConsent: false, // SMS consent checkbox
      smsConsentError: "", // SMS consent error
      emailConsent: false, // Email consent checkbox
      emailConsentError: "", // Email consent error
      isSubmitting: false,
      submitSuccess: false,
      submitError: false,
      quizDataMapping: {
        apiParam1: "on_market",
        apiParam2: "prop_type",
        apiParam3: "beds",
        apiParam4: "reasons_for_sale",
        apiParam5: "speed_of_sale",
        apiParam6: "property_condition",
      },
      phoneHlrStatus: null,  // This will store the active status
      country: "", // New property for the country
      localUtmCampaign: this.utmCampaign,
      localUtmSource: this.utmSource,
      localUtmMedium: this.utmMedium,
      localUtmKeyword: this.utmKeyword,
      localUtmDevice: this.utmDevice,
      localUtmMatch: this.utmMatch,
      localAdId: this.adId,
      localAdSetId: this.adSetId,
      localGclid: this.gclid,
    };
  },
  created() {
    // Your existing created hook code for handling utm parameters
    // ...
  },
  methods: {
    validateFirstName() {
      if (!this.firstName) {
        this.firstNameError = "First name is required";
      } else {
        this.firstNameError = "";
      }
    },
    validateLastName() {
      if (!this.lastName) {
        this.lastNameError = "Last name is required";
      } else {
        this.lastNameError = "";
      }
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email) {
        this.emailError = "Email is required";
      } else if (!emailPattern.test(this.email)) {
        this.emailError = "Invalid email address";
      } else {
        this.emailError = "";
      }
    },
    validatePhone() {
      // Updated regex to match US phone numbers with optional +1 and various formats
      const phonePattern = /^(?:\+1\s?)?(\(\d{3}\)\s?|\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;
      if (!this.phone) {
        this.phoneError = "We need a valid phone number to make an offer.";
      } else if (!phonePattern.test(this.phone)) {
        this.phoneError = "Invalid phone number. Please enter a valid U.S. number.";
      } else {
        this.phoneError = "";
      }
    },
    validatePrivacyPolicy() {
      if (!this.privacyPolicy) {
        this.privacyPolicyError = "You must agree to the Privacy Policy";
      } else {
        this.privacyPolicyError = "";
      }
    },
    validateConsentToBMV() {
      if (!this.consentToBMV) {
        this.consentToBMVError = "You must consent to a BMV offer.";
      } else {
        this.consentToBMVError = "";
      }
    },
    validateSMSConsent() {
      if (!this.smsConsent) {
        this.smsConsentError = "You must agree to receive SMS messages to continue.";
      } else {
        this.smsConsentError = "";
      }
    },
    validateEmailConsent() {
      if (!this.emailConsent) {
        this.emailConsentError = "You must agree to receive emails to continue.";
      } else {
        this.emailConsentError = "";
      }
    },
    async submitForm() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validatePhone();
      this.validatePrivacyPolicy();
      this.validateConsentToBMV();
      this.validateSMSConsent();
      this.validateEmailConsent();

      if (
        this.firstNameError ||
        this.lastNameError ||
        this.emailError ||
        this.phoneError ||
        this.privacyPolicyError ||
        this.consentToBMVError ||
        this.smsConsentError ||
        this.emailConsentError
      ) {
        return;
      }

      this.isSubmitting = true;
      const finalLeadScore = this.leadScore * 3;

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      try {
        const formData = new FormData();
        formData.append("lp_offer_id", this.lpOfferId);
        formData.append("lp_campaign_id", this.lpCampaignId);
        formData.append("site_ref", "rapidsale.com");
        formData.append("first_name", this.firstName);
        formData.append("last_name", this.lastName);
        formData.append("email_address", this.email);
        formData.append("phone_home", this.phone);
        formData.append("accepted_terms", this.privacyPolicy ? "1" : "0");
        formData.append('lead_score', finalLeadScore);
        formData.append("consent_sms", this.smsConsent ? "1" : "0"); // SMS consent
        formData.append("consent_email", this.emailConsent ? "1" : "0"); // Email consent

        // Append address data if available
        if (this.addressData && this.addressData.street) {
          formData.append("address", this.addressData.street);
          formData.append("city", this.addressData.city);
          formData.append("state", this.addressData.state);
          formData.append("zip_code", this.addressData.zipcode);
          formData.append("country", "US"); // Assuming US addresses
        }

        // Append quizData fields to formData
        for (const key in this.quizData) {
          const apiParamName = this.quizDataMapping[key];
          if (apiParamName) {
            formData.append(apiParamName, this.quizData[key]);
          }
        }

        // Append UTM parameters if available
        if (this.localUtmCampaign) {
          formData.append('utm_campaign', this.localUtmCampaign);
        }
        if (this.localUtmSource) {
          formData.append('utm_source', this.localUtmSource);
        }
        if (this.localUtmMedium) {
          formData.append('utm_medium', this.localUtmMedium);
        }
        if (this.localUtmKeyword) {
          formData.append('utm_keyword', this.localUtmKeyword);
        }
        if (this.localUtmDevice) {
          formData.append('utm_device', this.localUtmDevice);
        }
        if (this.localGclid) {
          formData.append('gclid', this.localGclid);
        }
        if (this.localUtmMatch) {
          formData.append('utm_matchtype', this.localUtmMatch);
        }

        await axios.post("https://leadseeker.leadspediatrack.com/post.do", formData, config);

        this.submitSuccess = true;
        this.submitError = false;
      } catch (error) {
        this.submitSuccess = false;
        this.submitError = true;
        console.error("Error posting the form:", error);
      }

      this.isSubmitting = false;

      if (this.submitSuccess) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'leadSubmission',
          'lead_score': this.leadScore,
          'email': this.email,
          'gclid': this.gclid,
        });

        // Redirect to the thank you page or display success message
        localStorage.setItem('userEmail', this.email);
        window.location.href = `/thank-you?email=${encodeURIComponent(this.email)}`;
      }
    },
  },
  computed: {
    isFormValid() {
      return (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phone &&
        this.privacyPolicy
      );
    },
    leadScore() {
      let score = 0;

      // Evaluate answers and assign scores based on your scoring system
      if (this.quizData.apiParam1 === 'No') {
        score += 10;
      }

      const propertyTypes = ['Detached', 'Semi-Detached', 'Bungalow'];
      if (propertyTypes.includes(this.quizData.apiParam2)) {
        score += 5;
      }

      const bedrooms = ['1', '2', '3', '4', '5+'];
      if (bedrooms.includes(this.quizData.apiParam3)) {
        score += 5;
      }

      const reasonsForSale = ['Stop Repossession', 'Stop Eviction', 'Mortgage Arrears', 'Financial', 'Other'];
      if (reasonsForSale.includes(this.quizData.apiParam4)) {
        score += 20;
      } else if (this.quizData.apiParam4 === 'Inherited' && this.quizData.apiParam5 === 'Within 7 Days') {
        score += 15;
      } else if (['Matrimonial', 'Health Reasons'].includes(this.quizData.apiParam4)) {
        score += 10;
      } else if (this.quizData.apiParam4 === 'Emigrating') {
        score += 8;
      }

      if (['ASAP', 'Within 7 Days'].includes(this.quizData.apiParam5)) {
        score += 20;
      } else if (this.quizData.apiParam5 === '1 - 3 Weeks') {
        score += 10;
      }

      if (this.quizData.apiParam6 === 'Needs TLC') {
        score += 20;
      }

      return score;
    },
  },
};
</script>

<style>
@media (max-width: 640px) {
  .small-device-text {
    font-size: 0.525rem; /* Adjust the value to your preference */
  }
}
</style>

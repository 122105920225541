<template>
  <div class="w-full max-w-md mx-auto py-4 px-6">
    <!-- Form Container with Light Gray Background and Border -->
    <form @submit.prevent="proceedToNextStep" class="bg-gray-100 border border-gray-300 shadow-lg rounded-lg px-6 pt-6 pb-4">
      <!-- Form Title -->
      <h2 class="text-2xl font-bold text-green-800 text-center mb-4">
        Get Started...
      </h2>
      <!-- Optional Subtitle -->
      <p class="text-sm text-gray-600 text-center mb-6">
        No Obligation. Instant Decision. No Fees, Sell Fast, Move Easy.
      </p>
      <!-- Input Field -->
      <div class="mb-4">
        <label class="block text-gray-700 font-semibold mb-2" for="addressInput">
          Enter Your Street Address
        </label>
        <div class="relative">
          <input
            v-model="addressInput"
            @input="debouncedSearchAddress"
            class="mobile-input appearance-none w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-400 rounded-md bg-white"
            id="addressInput"
            type="text"
            placeholder="e.g., 123 Main St"
          />
          <!-- Suggestions List -->
          <div class="absolute mt-1 w-full z-10" v-click-outside="closeSuggestions">
            <ul
              v-if="addressSuggestions.length > 0 && suggestionsVisible"
              class="bg-white rounded-md shadow-lg text-gray-700 max-h-60 overflow-y-auto border border-gray-300"
            >
              <li
                v-for="(suggestion, index) in addressSuggestions"
                :key="index"
                class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                @click="selectAddress(suggestion)"
              >
                {{ formatSuggestion(suggestion) }}
              </li>
            </ul>
          </div>
        </div>
        <!-- Error Message -->
        <div v-if="submitted && !addressInput" class="text-red-500 text-sm mt-2">
          Please enter your home address.
        </div>
      </div>
      <!-- Submit Button -->
      <div class="flex justify-center">
        <button
          class="submit-btn bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500"
          type="submit"
        >
          Get Offer
        </button>
      </div>
      <!-- Optional: Additional Sections like Reviews -->
      <!-- ... -->
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        el.clickOutsideHandler = function (event) {
          if (!el.contains(event.target)) {
            vnode.context[binding.expression](event);
          }
        };
        document.addEventListener("click", el.clickOutsideHandler);
      },
      unbind(el) {
        document.removeEventListener("click", el.clickOutsideHandler);
      },
    },
  },
  data() {
    return {
      addressInput: "", // Initialize as an empty string
      addressSuggestions: [],
      submitted: false,
      suggestionsVisible: false,
      debounceTimer: null,
      userZipCode: "", // Optional: Store user's ZIP code if collected separately
    };
  },
  methods: {
    debouncedSearchAddress() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.searchAddress();
      }, 300); // Adjust the delay as needed
    },
    async searchAddress() {
      if (this.addressInput.trim()) {
        try {
          // Build the API URL
          let apiUrl = `https://us-autocomplete-pro.api.smarty.com/lookup?key=213051545395687304&search=${encodeURIComponent(
            this.addressInput
          )}&max_results=10`;

          // Include only addresses within the specified ZIP code
          // If you have the ZIP code stored in `userZipCode`, include it
          if (this.userZipCode) {
            apiUrl += `&include_only_zip_codes=${this.userZipCode}`;
          }

          const response = await axios.get(apiUrl);

          if (response.data.suggestions && response.data.suggestions.length > 0) {
            this.addressSuggestions = response.data.suggestions;
            this.suggestionsVisible = true;
          } else {
            this.addressSuggestions = [];
            this.suggestionsVisible = false;
          }
        } catch (error) {
          console.error("Error fetching address suggestions:", error);
        }
      } else {
        this.addressSuggestions = [];
        this.suggestionsVisible = false;
      }
    },
    closeSuggestions() {
      this.suggestionsVisible = false;
    },
    selectAddress(suggestion) {
      if (suggestion) {
        // Construct the full address
        this.addressInput = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
        this.addressSuggestions = [];
        this.suggestionsVisible = false;

        // Emit detailed address data
        this.$emit("addressSelected", {
          street: suggestion.street_line,
          city: suggestion.city,
          state: suggestion.state,
          zipcode: suggestion.zipcode,
        });

        // Proceed to the next step
        this.proceedToNextStep();
      } else {
        console.error("Selected suggestion is invalid:", suggestion);
      }
    },
    proceedToNextStep() {
      this.submitted = true;
      if (this.addressInput && this.addressInput.trim()) {
        // Additional logic after address selection
      } else {
        console.error("Address input is invalid:", this.addressInput);
        // Handle the case where no address is entered
      }
    },
    formatSuggestion(suggestion) {
      // Helper method to format suggestion display
      return `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
    },
  },
};
</script>

<style scoped>
/* Form container styling */
.w-full {
  max-width: 100%;
}

/* Form styling */
.bg-gray-100 {
  background-color: #f3f4f6; /* Gray-100 */
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #d1d5db; /* Gray-300 */
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

/* Input field styling */
.mobile-input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.375rem; /* Rounded-md */
  border: 1px solid #9ca3af; /* Gray-400 */
  background-color: #ffffff;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.mobile-input:focus {
  outline: none;
  border-color: #3b82f6; /* Blue-500 */
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3); /* Blue-500 at 30% opacity */
}

/* Submit button styling */
.submit-btn {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 0.375rem; /* Rounded-md */
}

.submit-btn:hover {
  transform: scale(1.05);
}

/* Suggestions list styling */
ul {
  margin-top: 0;
}

li {
  font-size: 1rem;
}

/* Error message styling */
.text-red-500 {
  color: #ef4444; /* Red-500 */
}

/* Responsive adjustments */
@media (max-width: 640px) {
  /* Adjust form padding */
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  /* Adjust heading size */
  h2 {
    font-size: 1.5rem;
  }

  /* Adjust input and button sizes */
  .mobile-input,
  .submit-btn {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }
}
</style>


<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover" src="@/assets/rapid-sale-row-houses2.jpg" alt="Cómo Funciona Rapid Sale">
      <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <!-- Agregar el estilo del cuadro negro aquí -->
      <div class="bg-black opacity-80 rounded-lg p-6">
        
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">Cómo Trabajamos</h1>

        <h2 class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold tracking-tight text-white">Descubre Transacciones Fiables y Rápidas de Venta de Casas en Efectivo</h2>
        
        <p class="mt-6 max-w-3xl text-xl text-white">
          En el corazón de nuestro servicio está nuestra experiencia en compras de casas en efectivo, perfeccionada a través de años de transacciones exitosas. Tenemos una rica historia de adquisición y gestión de una cartera diversa de casas. Esta amplia experiencia ha consolidado nuestro estatus como compradores de casas confiables y experimentados.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          Elegir nuestros servicios significa optar por una experiencia simplificada y sin estrés. Nuestro proceso comienza con una evaluación exhaustiva de su casa, lo que lleva a una oferta en efectivo justa y firme. Una vez que acepte, procederemos con la redacción de un acuerdo formal a través de nuestro equipo legal, garantizando una transacción que no solo es rápida, sino también transparente y segura.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          Estamos comprometidos a garantizar su comodidad durante todo el proceso. Con este fin, cubrimos sus costos de cierre y ofrecemos flexibilidad al establecer la fecha de cierre. ¿Necesita acceso rápido a los fondos? Podemos finalizar el trato en tan solo 7 días. Si necesita más tiempo, podemos ajustar nuestro cronograma para adaptarlo a su agenda.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          Confíe en nosotros para una venta de casa en efectivo directa y confiable que prioriza sus necesidades y comodidad.
        </p>
        
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/es/')">
          Obtenga una oferta en efectivo gratuita <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: 'HowWeWorkEs',
  metaInfo() {
    return {
      title: 'Cómo Trabajamos | Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Cómo Funciona Rapid Sale USA - Obtén una oferta en efectivo gratuita hoy - Un equipo con años de experiencia.',
        },
        {
          name: 'keywords',
          content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.com/es/how-we-work' }
      ]
    };
  },
};
</script>

      
    
      
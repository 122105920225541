<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-cash-request.jpg" alt="Vendido">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl">Gracias por elegir Rapid Sale USA</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Agradecemos la oportunidad de ayudarle con la venta de su casa. Tenga la seguridad de que nuestro equipo ya está procesando su solicitud y se pondrá en contacto con usted pronto.
      </p>
      
      <h2 class="text-2xl text-white mt-4">Nuestro Compromiso con la Excelencia</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        La confianza y la fiabilidad son los pilares de nuestro servicio. Le invitamos a leer los testimonios de nuestros clientes para entender nuestra dedicación a la satisfacción del cliente.
      </p>

      <!-- Sección de Reseñas -->
      <div class="mt-6 max-w-3xl text-xl text-indigo-100">
        <p><strong>John D. (Cliente Verificado):</strong> "Profesionales, informativos y reconfortantes. El equipo fue excepcional."</p>
        <p><strong>Mary S. (Cliente Verificada):</strong> "Responsivos, amables y justos. Recomiendo encarecidamente a Rapid Sale USA."</p>
        <!-- Se pueden agregar más testimonios aquí -->
      </div>

      <h2 class="text-2xl text-white mt-4">La Diferencia de Rapid Sale USA</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Nuestro enfoque es directo y honesto. Con Rapid Sale USA, obtiene una oferta en efectivo directa sin tarifas ocultas ni bajadas de precios de última hora.
      </p>

      <h2 class="text-2xl text-white mt-4">Transparente y Apoyo</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Nuestro objetivo es hacer que el proceso de venta sea lo menos estresante posible, comprendiendo la urgencia y la sensibilidad que pueden acompañar a la venta de una casa.
      </p>

      <!-- Opciones de Contacto -->
      <div class="flex flex-col mt-8 text-left">
        <!-- Puede descomentar y actualizar la sección de WhatsApp si es aplicable en los EE. UU. -->
        <!--
        <div class="text-xl text-white mb-6">
          Haz clic para enviarnos un mensaje por WhatsApp
        </div>
        -->

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Teléfono
        </div>
        <div class="text-xl text-white mb-6">
          <a href="tel:18001234567" class="text-white">1-800-123-4567</a> <!-- Número gratuito de marcador de posición -->
        </div>

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Correo Electrónico
        </div>
        <div class="text-xl text-white mb-6">
          <a href="mailto:hello@rapidsaleusa.com" class="text-white">hello@rapidsaleusa.com</a>
        </div>
      </div>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Esperamos poder asistirle más adelante. Atentamente, Adam y el equipo de Rapid Sale USA.
      </p>
    </div>
  </div>
</template>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>

<script>
export default {
  name: 'ThankYouEs',
  
  metaInfo() {
    return {
      title: 'Gracias - Rapid Sale',
      meta: [
        {
          name: 'description',
          content: '¡Gracias por enviar los detalles de su propiedad para una oferta en efectivo gratuita!',
        },
        {
          name: 'keywords',
          content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo, gracias',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.co.uk/thank-you' }
      ]
    };
  },
};
</script>
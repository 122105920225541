<template>
  <div id="quiz-form" class='bg-white shadow-md rounded p-8' ref='quizForm'>
    <div v-if='currentQuestionIndex < questions.length'>
      <div class='flex items-center justify-between mb-1'>
        <div class='text-sm font-bold'>
          Step {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </div>
      </div>
      <div class='border-b-2 border-gray-200 mb-2'></div>

      <div class="progress-bar-container bg-gray-200 h-4 rounded">
        <!-- Progress Bar -->
        <div class="progress-bar bg-green-500 h-full rounded" :style="{width: progressBarWidth}"></div>
      </div>

    <div class='text-xl mb-4 text-blue-500 font-semibold text-center px-2 mt-3'>
      {{ questions[currentQuestionIndex].question }}
    </div>


      <select
        v-model='selectedAnswer'
        class='custom-dropdown group hover:bg-blue-200 cursor-pointer bg-gray-200 w-full rounded px-2 py-3 flex items-center mb-2'
      >
        <option value='' disabled>Select an option...</option>
        <option
          v-for='(answer, answerIndex) in questions[currentQuestionIndex].answers'
          :key='answerIndex'
          :value='answer'
        >{{ answer.text }}</option>
      </select>
      <p class='text-gray-500 flex items-center p-0 text-right pt-2'>
        <i class='text-green-600 fas fa-shield-alt mr-1'></i>
        Safe, secure &amp; confidential
      </p>
    </div>
    <div v-else>
      <div v-if='loading' class='flex items-center'>
        <div class='spinner w-8 h-8'></div>
        <span class='ml-2'>Checking your property, please wait...</span>
      </div>
      <div v-else>
        <div class='text-md mb-4'>
          <div class='container mx-auto max-w-md'>
            <p class="pb-2">
              Great!, based on our quick evaluation, we would like to make a cash offer on your property. We just need a few contact details, request your no-obligation cash offer below.
            </p>
          </div>
              <button id="free-cash-offer-button" class='bg-green-600 text-white font-bold py-3 px-4 rounded w-full shadow-md transform hover:shadow-lg transition duration-200'
              @click='submitQuiz()'>
              👉 Request My Free Cash Offer
            </button>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.custom-dropdown {
  border: 1px solid black;
  appearance: none;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>
export default {
  name: "QuizForm",
  data() {
    return {
      questions: [
        {
          question: 'Is your property currently listed for sale?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        {
          question: 'What type of property are you selling?',
          answers: [
          { text: 'Single-Family Home' },
          { text: 'Condominium' },
          { text: 'Townhouse' },
          { text: 'Multi-Family Home' },
          { text: 'Duplex' },
          { text: 'Triplex' },
          { text: 'Fourplex' },
          { text: 'Mobile Home' },
          { text: 'Manufactured Home' },
          { text: 'Land' },
          { text: 'Commercial Property' },
          { text: 'Other' },
          ],
        },
        {
          question: 'How many bedrooms?',
          answers: [
          { text: 'Studio' },
          { text: '1' },
          { text: '2' },
          { text: '3' },
          { text: '4' },
          { text: '5' },
          { text: '6+' },
          ],
        },
        
        {
          question: 'Reason for sale?',
          answers: [
          { text: 'Hurricane Damage' },
          { text: 'Inherited Property' },
          { text: 'Divorce' },
          { text: 'Downsizing' },
          { text: 'Job Relocation' },
          { text: 'Tired Landlord' },
          { text: 'Health Reasons' },
          { text: 'Upgrading' },
          { text: 'Vacant Property' },
          { text: 'Moving Closer to Family or Friends' },
          { text: 'Financial Difficulties' },
          { text: 'Facing Foreclosure' },
          { text: 'Behind on Mortgage Payments' },
          { text: 'Retirement' },
          { text: 'Unwanted Property' },
          { text: 'Other' },

          ],
        },
        {
          question: 'How quickly do you need to sell?',
          answers: [
            { text: 'Immediately (ASAP)' },
            { text: 'Within 7 Days' },
            { text: 'Within 30 Days' },
            { text: '1 - 2 Months' },
            { text: '3 - 6 Months' },
            { text: 'No Rush' },
          ],
        },
        {
          question: 'What is the property\'s condition?',
          answers: [
            { text: 'Poor (Major Repairs Needed)' },
            { text: 'Fair (Some Repairs Needed)' },
            { text: 'Good (Move-in Ready)' },
            { text: 'Excellent (Recently Renovated)' },
          ],
        },
      ],
      currentQuestionIndex: 0,
      answers: [],
      selectedAnswer: '',
      loading: false,
    };
  },
  watch: {
  selectedAnswer(answer) {
    if (answer) {
      this.answers.push(answer);
      this.selectedAnswer = "";
      this.currentQuestionIndex++;

      if (this.currentQuestionIndex >= this.questions.length) {
        console.log("All questions answered. Submitting quiz...");
        this.loading = true; // Show the spinner
        console.log("Loading set to true. Spinner should be visible.");

        setTimeout(() => {
          // Simulate the quiz submission process
          const quizData = this.constructQuizData();
          this.$emit('completed', quizData);
          console.log("Form submitted. Waiting for response...");

          // Hide the spinner after a delay
          setTimeout(() => {
            this.loading = false;
            console.log("Loading set to false. Spinner should disappear.");
          }, 1000); // Hide the spinner 1 second after submission
        }, 3000); // Delay the form submission for 3 seconds
      }
    }
  },
},


  methods: {
    scrollToTop() {
      this.$refs.quizForm.scrollIntoView({ behavior: "smooth", block: "start", inline: "start", scrollBehavior: "auto" });
    },

    constructQuizData() {
      return {
        apiParam1: this.answers[0].text,
        apiParam2: this.answers[1].text,
        apiParam3: this.answers[2].text,
        apiParam4: this.answers[3].text,
        apiParam5: this.answers[4].text,
        apiParam6: this.answers[5].text,
      };
    },
    submitQuiz() {
    // Simulate a network request or processing delay
    setTimeout(() => {
      const quizData = this.constructQuizData();
      this.$emit('completed', quizData);

      // Hide the spinner after a delay
      setTimeout(() => {
        this.loading = false;
        console.log("Loading set to false. Spinner should disappear.");
      }, 1000); // Hide the spinner 1 second after processing
    }, 3000); // Simulate a 3-second processing time
  },
  },

  mounted() {
    this.scrollToTop();
  },
  computed: {
    progressBarWidth() {
      const progressPercentage = (this.currentQuestionIndex / this.questions.length) * 100;
      return `${progressPercentage}%`;
    },
  },
};
</script>
<style>
.progress-bar-container {
  /* Container styles */
  width: 100%;
}

.progress-bar {
  /* Initial bar styles */
  width: 0%;
  transition: width 0.3s ease;
}

</style>
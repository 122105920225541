<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-contact.jpg" alt="">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">Contáctenos</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Puede contactarnos a través de los siguientes detalles:</p> 

      <!-- Sección de Dirección -->
      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-map-marker-alt mr-2 mt-6"></i> Dirección
      </div>
      <div class="text-xl text-white mb-6">
        <p>
          Rapid Sale, Inc<br />
          1 North Clematis Street, Suite 550<br />
          West Palm Beach, FL 33401
        </p>
      </div>

      <!-- Sección de Teléfono -->
      <div class="flex flex-col mt-4 text-left">
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Teléfono
        </div>
        <div class="text-xl text-white mb-6">
          <a href="tel:+18136423959" class="text-white">+1 813-642-3959</a> <!-- Número de teléfono de ejemplo -->
        </div>

        <!-- Sección de Correo Electrónico -->
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Correo Electrónico
        </div>
        <div class="text-xl text-white mb-6">
          hello@rapidsale.com
        </div>
      </div>

      <!-- Botón de Llamada a la Acción -->
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/es/')">
        Obtenga una Oferta en Efectivo Gratuita <i class="fas fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</template>


<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

  
export default {
    name: 'ContactEs',
    metaInfo() {
      return {
        title: 'Contáctenos | Rapid Sale USA | Obtenga una Oferta en Efectivo Hoy',
        meta: [
          {
            name: 'description',
            content: 'Rapid Sale USA - Contáctenos hoy.',
          },
          {
            name: 'keywords',
            content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo',
          },
          ],
    };
  },
};
</script>


  
<template>
  <div class="relative" style="height: auto;">
    <AppHeader />
    <router-view :key="$route.path"></router-view>
    <div
      v-if="$route.path === '/es/'"
      class="header-container flex flex-col items-center bg-cover bg-center relative"
      :style="`background-image: url(${headerImageUrl})`"
    >
      <!-- Overlay Mejorado con Gradiente -->
      <div class="overlay absolute inset-0 bg-gradient-to-b from-blue-900 via-transparent to-blue-900 opacity-80"></div>      

      <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
        <div class="key-points-container w-full px-4 sm:w-3/4 lg:w-2/3 pt-10 sm:pt-20 md:pt-24">
          <!-- Contenedor Mejorado con Fondo Semitransparente -->
          <div class="mt-3 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-xl p-0 shadow-2xl text-center">
            <!-- Encabezado Actualizado con Énfasis -->
            <h1 class="text-3xl md:text-5xl font-extrabold text-blue-900 tracking-wide pt-6">
              Vende tu casa rápido por dinero en efectivo
            </h1>
            <!-- Subtítulo para Mayor Claridad -->
            <p class="text-xl md:text-2xl text-gray-700 mb-3 pt-3">
              Sin comisiones, sin complicaciones, en cualquier condición
            </p>
            <!-- Componente del Formulario de Dirección -->
            <AddressForm @addressSelected="onAddressSelected" class="w-full max-w-lg mx-auto mb-8" />
            <!-- Llamada Opcional a la Acción (Eliminada según tu solicitud) -->
            <!--
            <button class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full mt-4 transition duration-300 ease-in-out transform hover:scale-105">
              Obtén tu oferta gratis ahora
            </button>
            -->
            <!-- Lista Actualizada con Iconos y Mejor Espaciado -->
            <ul class="grid grid-cols-1 sm:grid-cols-2 gap-6 text-left text-gray-900 mt-8 pl-6 pr-6 pb-8">
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Un proceso de venta rápido</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Simplifica la venta de tu casa</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Precios justos por las casas</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Sin visitas ni letreros en el jardín</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Sin bajadas de precio de último minuto</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Tú eliges la fecha de cierre</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">Compramos en cualquier condición</span>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check-circle text-green-600 text-2xl mr-3 mt-1"></i>
                <span class="text-lg font-medium">¡Nosotros cubrimos todos los costos!</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-4">
          <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-10 text-center">
            <p class="text-xs font-bold mb-2">
              <span class="text-red-500 mr-1">&#9888;</span>
              Nuestras ofertas son competitivas, y no estás obligado a aceptarlas. No tienes nada que perder.
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
        <div class="text-xl sm:text-3xl font-bold text-white mb-2">
          ¿Listo para vender? Cuéntanos sobre tu propiedad...
        </div>
        <div class="text-md sm:text-lg text-white mb-8">
          ¡Solo toma un momento!
        </div>

        <QuizForm @completed="onQuizComplete" />
        
        <div class="sm:max-w-sm mx-auto text-sm text-white pt-5 pb-10" style="font-size: 10px;">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 inline-block mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>
          Esta información nos ayuda a personalizar nuestra oferta para satisfacer tus necesidades específicas.
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10" :class="{ 'hidden': !showContact }">
        <ContactForm :address-data="addressData" 
                      :quiz-data="quizData" 
                      :gclid="gclid" 
                      :utmCampaign="utmCampaign" 
                      :utmSource="utmSource" 
                      :utmMedium="utmMedium"
                      :utmKeyword="utmKeyword"
                      :utmMatch="utmMatch" 
                      :utmDevice="utmDevice" />
      </div>
    </div>

    <!-- Contenedor de Widget de Reseñas -->
    <div class="review-widget-container bg-gray-100 py-7" v-if="!$route.path.startsWith('/landerv1/') && !$route.path.startsWith('/financial/') && !$route.path.startsWith('/landerv2/')">
      <div class="max-w-4xl mx-auto">
        <h2 class="text-4xl font-semibold text-blue-600 mb-6 text-center">Nuestras Reseñas</h2>
        <p class="text-center text-lg text-gray-700 mb-8">Expandiendo nuestra exitosa operación en el Reino Unido a los EE.UU., traemos con nosotros un compromiso con el servicio excepcional respaldado por más de 135 reseñas de cinco estrellas de nuestro confiable recolector de reseñas de terceros.</p>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div v-for="review in reviews" :key="review.id" class="review-card bg-white p-4 rounded-lg shadow-lg">
            <div class="flex justify-center mb-4">
              <img src="@/assets/reviewsio-logo.png" alt="Logo de Reviews.io" class="logo">
            </div>
            <div class="flex items-center mb-2">
              <div class="text-lg font-bold">{{ review.name }}</div>
            </div>
            <div class="flex items-center mb-2">
              <div class="text-yellow-500">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
            </div>
            <div class="text-gray-700">{{ review.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/landerV1/') && !$route.path.startsWith('/landerV2/') && !$route.path.startsWith('/thank-you')" class="guaranteed-offer p-6">
    <h2 class="text-3xl font-semibold text-blue-600 mb-6 text-center">¿Quieres una oferta garantizada?</h2>
    <p class="text-lg px-4 mb-6 text-gray-700">
      Sin comisiones, con completa flexibilidad en tu fecha de cierre, 100% confidencialidad, cero estrés y sin agentes inmobiliarios. Proporcionamos velocidad, certeza, simplicidad.
    </p>
    <div class="offer-steps grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
      <div class="step">
        <i class="fas fa-comments chat-bubble-icon text-3xl text-green-500"></i>
        <p class="mt-2">Cuéntanos sobre tu propiedad</p>
      </div>
      <div class="step">
        <i class="fas fa-handshake cash-offer-icon text-3xl text-green-500"></i>
        <p class="mt-2">Acepta nuestra oferta en efectivo</p>
      </div>
      <div class="step">
        <i class="fas fa-university bank-icon text-3xl text-green-500"></i>
        <p class="mt-2">Dinero en el banco en 7 días</p>
      </div>
    </div>
    <p class="text-lg px-4 mt-6 mb-8 text-gray-700">
      Tú eliges la fecha de cierre. ¡Nosotros cubrimos todos los costos! Nuestro servicio es simple, sin estrés, dándote tranquilidad.
    </p>
    <button class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg text-xl mx-auto block transition duration-300 ease-in-out" @click="goToTop">
      Obtén una oferta en efectivo gratis hoy<i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>

  <div v-if="!($route.path.startsWith('/landerV1/') || $route.path.startsWith('/landerV2/') || $route.path.startsWith('/articles/') || $route.path.startsWith('/thank-you'))" class="bg-blue-900 text-white p-6 rounded-lg shadow-lg">
    <div class="max-w-4xl mx-auto text-center">
      <h2 class="text-3xl font-bold mb-6">El Servicio Rapid Sale - Ventas Rápidas de Casas por Efectivo</h2>
      <p class="mb-6">Vende tu casa rápido por efectivo, sin comisiones, sin esperas y sin complicaciones.</p>

      <ul class="list-disc list-inside mb-6 text-left">
        <li>Pago en efectivo inmediato directamente a tu banco.</li>
        <li>Sin retrasos, sin interminables visitas.</li>
        <li>Sin reducciones de precio de último minuto.</li>
        <li>100% sin comisiones a pagar.</li>
        <li>Asesores expertos en bienes raíces.</li>
        <li>Especialistas en una variedad de situaciones.</li>
      </ul>

      <p class="mb-6">Somos compradores de efectivo genuinos con fondos listos, asegurando una transacción rápida y segura. Consulta nuestras reseñas y experimenta la diferencia Rapid Sale.</p>

      <!-- Imagen de la oficina con el personal -->
      <div class="mb-6">
        <img src="@/assets/rapid-staff-image.jpg" alt="Personal de Rapid Sale" class="mx-auto rounded-lg shadow-lg">
      </div>

      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg mt-6 text-xl" @click="goToTop">
        Obtén una oferta en efectivo gratis <i class="fas fa-arrow-right ml-2"></i>
      </button>

      <p class="text-sm mt-6 italic">Cuidado con los intermediarios que se hacen pasar por compradores en efectivo. Siempre verifica la autenticidad y lee reseñas genuinas.</p>
    </div>
  </div>

  <!-- Comparación -->
  <div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/thank-you')" class="bg-gray-200 py-10">
    <div class="bg-white p-6 rounded-lg shadow-lg text-center max-w-4xl mx-auto">
      <h2 class="text-3xl font-bold mb-6 text-blue-800">Podemos ayudarte en cualquier circunstancia</h2>
      <p class="mb-6 text-gray-700">
        A lo largo de los años, hemos ayudado a innumerables propietarios a navegar por una variedad de situaciones difíciles.
        Ya sea deuda, problemas con pagos de hipoteca, riesgo de ejecución hipotecaria, pérdida de un ser querido, divorcio u otros desafíos,
        estamos aquí para ofrecer soluciones rápidas y efectivas.
      </p>

      <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Divorcio</p>
        </div>

        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Enfrentando Ejecución Hipotecaria</p>
        </div>

        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Atrasado en Hipoteca</p>
        </div>

        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Jubilación</p>
        </div>

        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Herencia</p>
        </div>

        <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 13l4 4L19 7" />
          </svg>
          <p class="font-semibold">Y más...</p>
        </div>
      </div>
      <p class="mb-2 text-gray-700 pt-4">
        No importa lo compleja que sea tu situación, estamos preparados para proporcionarte el apoyo y las soluciones que necesitas.
      </p>

      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg mt-2 text-xl"
        @click="goToTop">
        Obtén una oferta en efectivo gratis <i class="fas fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>

  <!-- Sección de Preguntas Frecuentes -->
  <div id="app" class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12" v-if="!$route.path.startsWith('/articles/') && $route.path !== '/thank-you'" >
    <div class="text-center">
      <h2 class="text-3xl font-bold text-gray-900">Preguntas Frecuentes</h2>
    </div>
    <div class="mt-8">
      <dl class="space-y-6">
        <div v-for="(item, index) in faqs" :key="index" class="space-y-2">
          <dt class="cursor-pointer px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200" @click="toggle(index)" :class="{'text-gray-900': item.isOpen, 'text-gray-700': !item.isOpen}">
            <div class="flex justify-between items-center">
              {{ item.question }}
              <svg :class="{'rotate-180': item.isOpen}" class="inline-block w-4 h-4 transition-transform duration-200" viewBox="0 0 24 24">
                <path d="M19 9l-7 7-7-7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </dt>
          <dd v-show="item.isOpen" class="text-gray-600 px-4 py-2 bg-gray-100">
            {{ item.answer }}
          </dd>
        </div>
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
          Obtén una oferta en efectivo gratis <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </dl>
    </div>
  </div>

  <div v-if="!$route.path.startsWith('/articles/')" class="bg-gray-200 py-8">
    <div class="max-w-3xl mx-auto">
      <h2 class="text-2xl font-bold mb-4 text-center">Como se vio en:</h2>
      <div class="flex flex-wrap justify-center items-center">
        <div class="mx-4 my-2">
          <img src="@/assets/logo1.webp" alt="Logo 1" class="h-12">
        </div>
        <div class="mx-4 my-2">
          <img src="@/assets/logo2.png" alt="Logo 2" class="h-12">
        </div>
        <div class="mx-4 my-2">
          <img src="@/assets/logo3.png" alt="Logo 3" class="h-12">
        </div>
        <div class="mx-4 my-2">
          <img src="@/assets/logo4.png" alt="Logo 4" class="h-12">
        </div>
        <div class="mx-4 my-2">
          <img src="@/assets/logo5.png" alt="Logo 5" class="h-12">
        </div>
      </div>
    </div>
  </div>

  <!-- Pie de página -->
  <footer class="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8">
          <img class="h-7" src="@/assets/rapid-logo-white.svg?color=indigo&shade=500" alt="Nombre de la empresa">
          <p class="text-sm leading-6 text-gray-300">Como especialistas en venta rápida de casas, ofrecemos una experiencia sin complicaciones con comisiones ocultas. Nuestras atractivas ofertas en efectivo se adaptan a tu conveniencia. Entendemos que cada propiedad tiene circunstancias únicas, por lo que nuestro equipo evalúa a fondo cada hogar según sus méritos individuales.</p>
          <div class="flex space-x-6">
            <a href="https://www.facebook.com/rapidsaleukofficial/" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">Facebook</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
              </svg>
            </a>

            <a href="https://twitter.com/RapidSaleUk" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">Twitter</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Enlaces útiles</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="https://rapidsale.com/es/" class="text-sm leading-6 text-gray-300 hover:text-white">Rapid Sale (Reino Unido)</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Compañía</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <router-link to="/es/about-us" class="text-sm leading-6 text-gray-300 hover:text-white">Sobre Nosotros</router-link>
                </li>
                <li>
                  <router-link to="/es/how-we-work" class="text-sm leading-6 text-gray-300 hover:text-white">Cómo Funciona</router-link>
                </li>
                <li>
                  <router-link to="/es/contact-us" class="text-sm leading-6 text-gray-300 hover:text-white">Contáctanos</router-link>
                </li>
              </ul>

              <ul role="list" class="mt-6 space-y-4">
                <li>
                    <a href="https://www.iubenda.com/privacy-policy/11237448" class="text-sm leading-6 text-gray-300 hover:text-white" target="_blank">Política de Privacidad</a>
                  </li>
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/11237448/cookie-policy" class="text-sm leading-6 text-gray-300 hover:text-white" target="_blank">Política de Cookies</a>
                  </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
        <p class="text-xs leading-5 text-gray-400">&copy; 2008 - {{ currentYear }} Rapid Sale, Inc.</p>
      </div>
    </div>
  </footer>
</template>




<script>

import AppHeader from "@/components/es/Header.vue"; // Spanish header component
import AddressForm from "@/components/es/AddressForm.vue";
import QuizForm from "@/components/es/QuizForm.vue";

import ContactForm from "@/components/es/ContactForm.vue";
//import CountdownTimer from "./components/CountdownTimer.vue";
//import ArticleList from './components/ArticleList.vue';


export default {
  components: {
    AppHeader,
    AddressForm,
    QuizForm,
    ContactForm,
    //CountdownTimer,
    //ArticleList, // Add this line to register the ArticleList component

  },
  data() {
  return {
    addressData: {
      street: "",
      postcode: "",
    },
    quizData: {},
    showQuiz: false,
    showContact: false,
    address: {},
    utmMatch: '',
    gclid: '',
    utmCampaign: '',
    utmSource: '',
    utmMedium: '',
    utmKeyword: '',
    utmDevice: '',
    headerImageUrl: require("@/assets/rapid-sale-row-houses2.webp"),
    currentYear: new Date().getFullYear(),
    faqs: [
  {
    question: '¿Por qué elegir Rapid Sale USA?',
    answer: 'Gracias por considerar nuestra empresa para la venta de tu casa. Ofrecemos una solución rápida, eficiente y sin estrés para vender tu casa. Nuestro equipo profesional opera con discreción y eficiencia, asegurando una transacción sin problemas. Contamos con los recursos financieros para tomar decisiones inmediatas y proporcionarte un pago en efectivo rápido, adaptado a tu cronograma. Ya sea que estés enfrentando desafíos financieros, cambios en tu estilo de vida o cualquier otra circunstancia, nuestro experimentado equipo está listo para ofrecerte un precio justo y competitivo por tu hogar. Somos conocidos por nuestro proceso transparente y directo, asegurando que estés bien informado en cada paso del camino. Elígenos para tener tranquilidad, un servicio rápido y una oferta en efectivo justa.',
    isOpen: false
  },
  {
    question: '¿Compran todo tipo de propiedades?',
    answer: '¡Absolutamente! Compramos una gran variedad de tipos de propiedades, incluyendo casas unifamiliares, condominios, casas adosadas, viviendas multifamiliares, casas móviles e incluso terrenos vacíos. No importa el estado de tu propiedad, ya sea en condiciones prístinas o que necesite reparaciones significativas, estamos interesados. También consideramos propiedades comerciales y viviendas únicas o no convencionales. Sea cual sea el tipo de propiedad que tengas, estamos listos para hacerte una oferta en efectivo justa.',
    isOpen: false
  },
  {
    question: '¿Tendré que pagar alguna tarifa o comisión?',
    answer: 'No, no tendrás que pagar ninguna tarifa o comisión. Cubrimos todos los costos de cierre y no hay cargos ocultos. La oferta que hacemos es la cantidad que recibirás al cierre. Nuestro objetivo es hacer que el proceso de venta sea lo más simple y directo posible para ti.',
    isOpen: false
  },
  {
    question: '¿Estoy enfrentando una ejecución hipotecaria, pueden ayudarme?',
    answer: 'Sí, podemos ayudarte. Entendemos la urgencia de tu situación y nos especializamos en transacciones rápidas para evitar la ejecución hipotecaria. Como compradores en efectivo, podemos cerrar rápidamente, a menudo en siete días, brindándote el alivio que necesitas. Nuestro equipo trabajará contigo para encontrar la mejor solución, proporcionando una oferta en efectivo justa y una fecha de cierre flexible para satisfacer tus necesidades.',
    isOpen: false
  },
  {
    question: '¿Cómo se diferencia su servicio de listar con un agente inmobiliario?',
    answer: 'Cuando listamos con un agente inmobiliario, el proceso puede tomar meses y no hay garantía de que tu casa se venda. También tendrás que lidiar con visitas, inspecciones, tasaciones y posibles problemas de financiamiento por parte del comprador. Con nosotros, puedes evitar todo eso. Compramos tu casa directamente por efectivo, en cualquier condición, y podemos cerrar según tu cronograma. No hay comisiones ni tarifas, y no necesitarás hacer reparaciones ni mejoras.',
    isOpen: false
  },
  {
    question: '¿Cómo determinan el precio de la oferta por mi casa?',
    answer: 'Consideramos varios factores al determinar nuestra oferta, incluyendo la ubicación de la propiedad, su estado actual, las reparaciones necesarias y el valor de casas comparables vendidas en la zona. Nuestro objetivo es ofrecer una oferta justa y competitiva que refleje el valor real de tu casa.',
    isOpen: false
  },
  {
    question: '¿Cuál es el proceso para venderme tu casa?',
    answer: 'El proceso es simple y directo:\n\n1. **Contáctanos**: Envíanos algunos datos básicos sobre tu propiedad.\n2. **Evaluación de la propiedad**: Revisamos los detalles y podemos programar una visita para evaluar el estado de tu hogar.\n3. **Recibir una oferta**: Te presentamos una oferta en efectivo sin compromiso.\n4. **Elegir la fecha de cierre**: Si aceptas, podemos cerrar en tu cronograma, a veces en tan solo siete días.\n\nDurante todo el proceso, nos encargamos de todo el papeleo y la logística, haciéndolo sin complicaciones para ti.',
    isOpen: false
  },
  {
    question: '¿Necesito hacer reparaciones o limpiar antes de vender?',
    answer: 'No, no es necesario hacer reparaciones ni limpiar. Compramos casas en su estado actual, lo que significa que no tienes que gastar tiempo ni dinero arreglando la propiedad. Ya sea que tu casa esté en perfecto estado o necesite trabajos significativos, estamos interesados.',
    isOpen: false
  },
  {
    question: '¿Hay alguna obligación cuando envío mi información?',
    answer: 'No, no hay ninguna obligación cuando envías tu información o recibes una oferta en efectivo de nuestra parte. Sabemos que vender tu casa es una gran decisión, y queremos que te sientas cómodo durante todo el proceso. Tú decides si vender tu casa a nosotros es la opción correcta para ti.',
    isOpen: false
  },
  {
    question: '¿Con qué rapidez pueden cerrar la compra de mi casa?',
    answer: 'Podemos cerrar en tan solo siete días, o según tu cronograma. Como usamos nuestros propios fondos y no dependemos de financiamiento tradicional, podemos movernos rápidamente y acomodar tu cronograma preferido.',
    isOpen: false
  }
],

reviews: [
  { id: 1, name: "Lucinda Fyfield", text: "No puedo reprochar la ayuda de Adam y su equipo. La comunicación fue excelente desde el principio hasta el final. En un momento estresante, Adam alivió el estrés. Recomiendo encarecidamente utilizar esta empresa y la recomendaré a quienes la necesiten en el futuro." },
  { id: 2, name: "David Lisle", text: "Recomiendo totalmente esta empresa. Profesionalismo en todo momento, me mantuvieron completamente informado. Claire fue excelente, respondió todas mis llamadas rápidamente, me tranquilizó durante todo el proceso y me dio toda la información y orientación que necesitaba. Absolutamente de primera clase." },
  { id: 3, name: "Mark Simkins", text: "Quería deshacerme de una casa sin complicaciones, ¡Adam lo hizo posible! Un par de llamadas telefónicas y la casa fue comprada por efectivo. La venta se completó en poco más de una semana, ¡con un mínimo esfuerzo de mi parte!" }
]

  };
},



  created() {
    this.parseUrlParameters();
    // Any other code you want to run during component creation
  },

  methods: {
    goToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onAddressSelected(address) {
      this.addressData = address;
      this.showQuiz = true;
    },

    onQuizComplete(quizResults) {
      this.quizData = quizResults;
      this.showQuiz = false;
      this.showContact = true;
    },

    parseUrlParameters() {
      const urlParams = new URLSearchParams(window.location.search);
      this.utmCampaign = urlParams.get('utm_campaign') || '';
      this.utmSource = urlParams.get('utm_source') || '';
      this.utmMedium = urlParams.get('utm_medium') || '';
      this.utmKeyword = urlParams.get('utm_keyword') || '';
      this.utmDevice = urlParams.get('utm_device') || '';
      this.utmMatch = urlParams.get('utm_matchtype') || '';
      this.adId = urlParams.get('adid') || '';
      this.adSetId = urlParams.get('adsetid') || '';
      this.gclid = urlParams.get('gclid') || '';
    },

    toggle(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },


      
  
   
    },

  

  // Other component options...
};
</script>

<style>

.header-container {
  /* Other styles */
  position: relative; /* Add relative positioning */
}

@media screen and (max-width: 640px) {
  .sm\:max-w-sm {
    max-width: 84%;
  }
}

.guaranteed-offer {
  margin: 20px 0;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.guaranteed-offer h2 {
  font-size: 2.5rem; 
  font-weight: bold; 
  margin-bottom: 15px; 
}

.offer-steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex-basis: 30%; 
  box-sizing: border-box; 
}

.chat-bubble-icon,
.cash-offer-icon,
.bank-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .step {
    flex-basis: 100%;
  }
}

.bg-custom-color {
  background-color: blue;
}

@media (max-width: 640px) {
  .small-device-table {
      font-size: 14px;
    }

  .small-device-table td,
  .small-device-table th {
      padding: 4px 8px;
    }
  }

.small-device-table tfoot {
      font-size: 6px;
}

/* Adding hover link styles */
.hover-link {
  transition: color 0.3s, transform 0.3s;
}

.hover-link:hover {
  color: red; /* Changing color on hover */
  transform: scale(1.05); /* Adding a little scaling effect on hover */
}

.R-ReviewsList {
    /* Add your styles here */
    margin: 0 auto;
    max-width: 100px; /* or 100% if you want it full width on smaller screens */
    padding: 15px;
    //background-color: #f8f8f8; /* Example background color */
  }

  /* Styles for individual review items */
  .R-ReviewsList__item {
    background-color: #ffffff; /* Set a white background for the review cards */
    border-radius: 8px;
    margin-bottom: 10px;
    //margin-left:1px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for depth */
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  }

  /* Hover effect for review items */
  .R-ReviewsList__item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  /* Styles for the review author's name */
  .cssVar-authorName {
    font-weight: bold;
    color: #333;
  }

  /* Styles for the star ratings */
  .R-RatingStars__stars {
    color: #ffcc00; /* Gold color for stars */
  }

  /* Styles for the review text */
  .R-ReviewsList__item--body {
    font-size: 1rem;
    line-height: 1.5;
    color: #666; /* Darker text for readability */
    margin-top: 10px;
  }

  /* Styles for the verified badge */
  .R-BadgeElement__icon {
    display: inline-block;
    margin-right: 5px;
    /* add more styles if needed */
  }

  /* Styles for the review date */
  .R-TextBody--xxxxs {
    text-align: right;
    color: #999;
    font-size: 0.75rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .R-ReviewsList {
      padding: 10px;
    }

    .R-ReviewsList__item {
      padding: 15px;
    }

    .R-ReviewsList__item--body {
      font-size: 0.9rem;
    }    
  }

  .review-card {
  max-width: 300px;
  margin: 0 auto;
}

/* Mobile-specific adjustments */
@media only screen and (max-width: 600px) {
  .flex.items-center.mb-2.px-2.sm\:px-0.pr-6 {
    padding-right: 4px; /* Reduce right padding */
    margin-bottom: 1px; /* Reduce space between items */
  }

  .fas.fa-check.text-green-500.mr-2 {
    margin-right: 8px; /* Reduce space after the icon */
  }
}


</style>


<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-contact.jpg" alt="">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">Contact Us</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">We can be contacted via the following details:</p> 

      <!-- Address Section -->
      <div class="text-2xl font-semibold text-indigo-200 mb-2">
        <i class="fas fa-map-marker-alt mr-2 mt-6"></i> Address
      </div>
      <div class="text-xl text-white mb-6">
        <p>
          Rapid Sale, Inc<br />
          1 North Clematis Street, Suite 550<br />
          West Palm Beach, FL 33401
        </p>
      </div>

      <!-- Phone Section -->
      <div class="flex flex-col mt-4 text-left">
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          <a href="tel:+18136423959" class="text-white">+1 813-642-3959</a> <!-- Placeholder toll-free number -->
        </div>

        <!-- Email Section -->
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          hello@rapidsale.com
        </div>
      </div>

      <!-- Call-to-Action Button -->
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</template>


<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

  
export default {
    name: 'ContactUs',
    metaInfo() {
      return {
        title: 'Contact Us | Rapid Sale USA | Get a Cash Offer Today',
        meta: [
          {
            name: 'description',
            content: 'Rapid Sale USA  - Contact us today.',
          },
          {
            name: 'keywords',
            content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
          },
          ],
    };
  },
};
</script>
  

  
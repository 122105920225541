<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Terms of Use</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Welcome to Rapid Sale, Inc. By using our website, you agree to comply with and be bound by these Terms of Use and our Privacy Policy, which govern our relationship with you concerning this site. If you do not agree with any part of these terms, please do not use our website.
      </p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        The terms "Rapid Sale, Inc," "we," or "us" refer to the owners of the website. The term "you" refers to the user or viewer of our website.
      </p>

      <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
        <li class="max-w-3xl text-xl text-indigo-100">
          The content on our website is for your general information and use only. It is subject to change without notice.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          We do not provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited except in accordance with the copyright notice, which forms part of these terms and conditions.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          From time to time, this website may also include links to other websites. These links are provided for your convenience to offer further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Your use of this website and any dispute arising out of such use of the website is subject to the laws of the State of Florida and the United States of America.
        </li>
      </ol>
    </div>
  </div>
</template>


  
  <style>
  .opacity-50 {
      opacity: 0.4;
  }
  </style>
      
      <script>
      export default {
        name: 'TermsOfUse',
        metaInfo() {
          return {
            title: 'Terms of Use - Rapid Sale (USA)',
            meta: [
              {
                name: 'description',
                content: 'Terms of Use',
              },
              {
                name: 'keywords',
                content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
              },
            ],
            link: [
            { rel: 'canonical', href: 'https://rapidsale.com/terms-of-use' }
          ]
          };
        },
      };
      </script>
    
      
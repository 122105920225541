import { createApp } from 'vue';
import App from './App.vue';
import AppEs from './AppEs.vue';
import './assets/css/styles.css';
import { createRouter, createWebHistory } from 'vue-router';
import AboutUs from './components/AboutUs.vue';
import AboutUsEs from './components/es/AboutUs.vue';
import HowWeWork from './components/HowWeWork.vue';
import HowWeWorkEs from './components/es/HowWeWork.vue';
import ContactUs from './components/ContactUs.vue';
import ContactEs from './components/es/ContactUs.vue';
import ThankYou from './components/ThankYou.vue';
import ThankYouEs from './components/es/ThankYou.vue';
import EmptyContent from './components/EmptyContent.vue';
import EmptyContentEs from './components/es/EmptyContent.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import PrivacyPolicyEs from './components/es/PrivacyPolicy.vue';
import TermsOfUse from './components/Terms.vue';
import TermsOfUseEs from './components/es/Terms.vue';
import CookiesPolicy from './components/CookiesPolicy.vue';
import LanderPage from './components/LandingPage.vue';
import LanderPageV2 from './components/LandingPageV2.vue';
import ArticlePage from './components/ArticlePage.vue';
import NotFound from './components/404.vue';
import LandingFinancial from './components/LandingFinancial.vue';

// Define routes with language support
const routes = [
  // English routes
  { path: '/', component: EmptyContent },
  { path: '/about-us', component: AboutUs },
  { path: '/how-we-work', component: HowWeWork },
  { path: '/contact-us', component: ContactUs },
  { path: '/thank-you', component: ThankYou },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/cookies-policy', component: CookiesPolicy },
  { path: '/landerV1/:slug', component: LanderPage, name: 'lander' },
  { path: '/landerV2/:slug', component: LanderPageV2, name: 'landerV2' },
  { path: '/financial/:slug', component: LandingFinancial, name: 'financial' },
  { path: '/articles/:slug', component: ArticlePage, name: 'article' },
  { path: '/404', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/404' },

  // Spanish routes
  { path: '/es/', component: EmptyContentEs },
  { path: '/es/about-us', component: AboutUsEs },
  { path: '/es/how-we-work', component: HowWeWorkEs },
  { path: '/contact-us', component: ContactUs },
  { path: '/es/contact-us', component: ContactEs },
  { path: '/es/thank-you', component: ThankYouEs },
  { path: '/es/privacy-policy', component: PrivacyPolicyEs },
  { path: '/es/terms-of-use', component: TermsOfUseEs },
  { path: '/es/cookies-policy', component: CookiesPolicy },
  { path: '/es/landerV1/:slug', component: LanderPage, name: 'landerEs' },
  { path: '/es/landerV2/:slug', component: LanderPageV2, name: 'landerV2Es' },
  { path: '/es/financial/:slug', component: LandingFinancial, name: 'financialEs' },
  { path: '/es/articles/:slug', component: ArticlePage, name: 'articleEs' },
  { path: '/es/404', component: NotFound },
  { path: '/es/:catchAll(.*)', redirect: '/es/404' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const pageTitle = to.matched.reduce((title, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      title = routeRecord.components.default.metaInfo().title;
    }
    return title;
  }, '');

  const metaDescription = to.matched.reduce((description, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      description = routeRecord.components.default.metaInfo().meta[0].content;
    }
    return description;
  }, '');

  const metaKeywords = to.matched.reduce((keywords, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      keywords = routeRecord.components.default.metaInfo().meta[1].content;
    }
    return keywords;
  }, '');

  // Update the document title and meta tags
  document.title = pageTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="keywords"]').setAttribute('content', metaKeywords);

  // Manage the canonical link
  const existingCanonical = document.querySelector('link[rel="canonical"]');
  if (existingCanonical) {
    existingCanonical.parentNode.removeChild(existingCanonical);
  }

  const canonical = document.createElement('link');
  canonical.setAttribute('rel', 'canonical');
  canonical.setAttribute('href', window.location.origin + to.fullPath); // Use `to.fullPath` for the full path.
  document.head.appendChild(canonical);

  next();
});

// Determine which root component to load based on the URL
const app = window.location.pathname.startsWith('/es')
  ? createApp(AppEs)
  : createApp(App);

app.use(router);
app.mount('#app');

<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-cash-request.jpg" alt="Sold">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl">Thank You for Choosing Rapid Sale USA</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        We appreciate the opportunity to assist you with your home-selling needs. Rest assured, our team is already processing your request and will be in touch soon.
      </p>
      
      <h2 class="text-2xl text-white mt-4">Our Commitment to Excellence</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Trust and reliability are the cornerstones of our service. We encourage you to read through our client testimonials to understand our dedication to customer satisfaction.
      </p>

      <!-- Reviews Section -->
      <div class="mt-6 max-w-3xl text-xl text-indigo-100">
        <p><strong>John D. (Verified Customer):</strong> "Professional, informative, and reassuring. The team was outstanding."</p>
        <p><strong>Mary S. (Verified Customer):</strong> "Responsive, friendly, and fair. Highly recommend Rapid Sale USA."</p>
        <!-- Additional testimonials can be added here -->
      </div>

      <h2 class="text-2xl text-white mt-4">The Rapid Sale USA Difference</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Our approach is straightforward and honest. With Rapid Sale USA, you get a direct cash offer with no hidden fees or last-minute price drops.
      </p>

      <h2 class="text-2xl text-white mt-4">Transparent and Supportive</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We aim to make the selling process as stress-free as possible, understanding the urgency and sensitivity that can accompany home sales.
      </p>



      
      <!-- Contact Options -->
      <div class="flex flex-col mt-8 text-left">
        <!-- You can uncomment and update the WhatsApp section if applicable in the USA -->
        <!--
        <div class="text-xl text-white mb-6">
          Click to message us on WhatsApp
        </div>
        -->

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          <a href="tel:18001234567" class="text-white">1-800-123-4567</a> <!-- Placeholder toll-free number -->
        </div>

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          <a href="mailto:hello@rapidsaleusa.com" class="text-white">hello@rapidsaleusa.com</a>
        </div>
      </div>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Looking forward to assisting you further. Warm regards, Adam and the Rapid Sale USA Team.
      </p>
    </div>
  </div>
</template>





<style>
.opacity-50 {
    opacity: 0.4;
}
</style>
    
    <script>
    export default {
      name: 'ThankYou',
      
      metaInfo() {
        return {
          title: 'Thank You - Rapid Sale',
          meta: [
            {
              name: 'description',
              content: 'Thank you for submitting your property details for a free cash offer!',
            },
            {
              name: 'keywords',
              content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
            },
          ],
          link: [
        { rel: 'canonical', href: 'https://rapidsale.co.uk/thank-you' }
        ]
        };
      },
    };
    </script>
  
    
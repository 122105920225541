<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Términos de Uso</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Bienvenido a Rapid Sale, Inc. Al utilizar nuestro sitio web, usted acepta cumplir con estos Términos de Uso y nuestra Política de Privacidad, que rigen nuestra relación con usted en relación con este sitio. Si no está de acuerdo con alguna parte de estos términos, por favor no utilice nuestro sitio web.
      </p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        Los términos "Rapid Sale, Inc," "nosotros," o "nos" se refieren a los propietarios del sitio web. El término "usted" se refiere al usuario o espectador de nuestro sitio web.
      </p>

      <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
        <li class="max-w-3xl text-xl text-indigo-100">
          El contenido de nuestro sitio web es solo para su información general y uso. Está sujeto a cambios sin previo aviso.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          No ofrecemos ninguna garantía sobre la exactitud, puntualidad, rendimiento, integridad o idoneidad de la información y los materiales encontrados o ofrecidos en este sitio web para cualquier propósito particular. Usted reconoce que dicha información y materiales pueden contener inexactitudes o errores, y nosotros excluimos expresamente nuestra responsabilidad por tales inexactitudes o errores en la máxima medida permitida por la ley.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Su uso de cualquier información o material en este sitio web es completamente bajo su propio riesgo, por lo que no seremos responsables. Será su responsabilidad asegurarse de que los productos, servicios o información disponibles a través de este sitio web cumplan con sus requisitos específicos.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Este sitio web contiene material que es propiedad de o está licenciado a nosotros. Este material incluye, pero no se limita a, el diseño, la disposición, la apariencia y los gráficos. La reproducción está prohibida, excepto de acuerdo con el aviso de derechos de autor, que forma parte de estos términos y condiciones.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Todas las marcas comerciales reproducidas en este sitio web, que no son propiedad de, ni están licenciadas a, el operador, se reconocen en el sitio web.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          El uso no autorizado de este sitio web puede dar lugar a una reclamación por daños y/o ser un delito penal.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          De vez en cuando, este sitio web puede incluir enlaces a otros sitios web. Estos enlaces se proporcionan para su conveniencia y para ofrecer más información. No significan que aprobemos el(los) sitio(s) web. No tenemos responsabilidad por el contenido de los sitios web vinculados.
        </li>
        <li class="max-w-3xl text-xl text-indigo-100">
          Su uso de este sitio web y cualquier disputa que surja de dicho uso del sitio web está sujeta a las leyes del Estado de Florida y los Estados Unidos de América.
        </li>
      </ol>
    </div>
  </div>
</template>

<style>
.opacity-50 {
    opacity: 0.4;
}
</style>

<script>
export default {
  name: 'TérminosDeUso',
  metaInfo() {
    return {
      title: 'Términos de Uso - Rapid Sale (USA)',
      meta: [
        {
          name: 'description',
          content: 'Términos de Uso',
        },
        {
          name: 'keywords',
          content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo, gracias',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.com/es/terms-of-use' }
      ]
    };
  },
};
</script>

<template>
  <div class="container mx-auto mt-0 p-4 bg-gray-100 rounded-md shadow-lg w-full max-w-xs sm:max-w-sm">
    <div class="bg-gray-100 p-4 rounded-md">
      <h2 class="text-xl sm:text-2xl font-extrabold mb-1">¡Buenas noticias!</h2>
      <p class="text-gray-800 text-sm md:text-base mb-2">
        Nos gustaría hacer una oferta en efectivo por su propiedad.
        Por favor, ingrese sus detalles a continuación.
      </p>
      <form id="request-cash-offer" @submit.prevent="submitForm">
        <!-- Primer Nombre -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="firstName"
            type="text"
            v-model="firstName"
            placeholder="Primer Nombre"
            @blur="validateFirstName"
          />
          <p class="text-red-500 text-xs italic" v-if="firstNameError">{{ firstNameError }}</p>
        </div>

        <!-- Apellido -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="lastName"
            type="text"
            v-model="lastName"
            placeholder="Apellido"
            @blur="validateLastName"
          />
          <p class="text-red-500 text-xs italic" v-if="lastNameError">{{ lastNameError }}</p>
        </div>

        <!-- Correo electrónico -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="email"
            type="text"
            v-model="email"
            placeholder="Correo electrónico"
            @blur="validateEmail"
          />
          <p class="text-red-500 text-xs italic" v-if="emailError">{{ emailError }}</p>
        </div>

        <!-- Teléfono -->
        <div class="mb-4">
          <input
            class="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:py-2 sm:px-3 sm:text-base"
            id="phone"
            type="text"
            v-model="phone"
            placeholder="Número de contacto"
            @blur="validatePhone"
          />
          <p class="block text-xs font-medium text-gray-500 small-device-text pt-1">* Necesitamos un número válido para hacer una oferta.</p>
          <p class="text-red-500 text-xs italic" v-if="phoneError">{{ phoneError }}</p>
        </div>

        <!-- Checkbox de Política de Privacidad -->
        <div class="mb-1">
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              class="form-checkbox text-green-500"
              v-model="privacyPolicy"
            />
            <span class="ml-2 text-xs">Acepto la <a href="/en/privacy-policy" class="text-blue-500" target="_blank">Política de Privacidad</a>.</span>
          </label>
          <p class="text-red-500 text-xs italic" v-if="privacyPolicyError">{{ privacyPolicyError }}</p>
        </div>

        <!-- Checkbox de Consentimiento para BMV -->
        <div class="mb-1">
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              class="form-checkbox text-green-500"
              v-model="consentToBMV"
            />
            <span class="ml-2 text-xs">Consiento una oferta por debajo del valor de mercado (BMV).</span>
          </label>
          <p class="text-red-500 text-xs italic" v-if="consentToBMVError">{{ consentToBMVError }}</p>
        </div>

        <!-- Casilla de Consentimiento para SMS -->
          <div class="mb-1">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox text-green-500"
                v-model="smsConsent"
              />
              <span class="ml-2 text-xs">
                Acepto recibir mensajes de texto de Rapid Sale respecto a ofertas en efectivo para propiedades. La frecuencia de los mensajes puede variar. Responde STOP para darte de baja.
              </span>
            </label>
            <p class="text-red-500 text-xs italic" v-if="smsConsentError">{{ smsConsentError }}</p>
          </div>

          <!-- Casilla de Consentimiento para Correo Electrónico -->
          <div class="mb-1">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox text-green-500"
                v-model="emailConsent"
              />
              <span class="ml-2 text-xs">
                Acepto recibir correos electrónicos de marketing de Rapid Sale sobre ofertas en efectivo para propiedades. Puedo darme de baja en cualquier momento.
              </span>
            </label>
            <p class="text-red-500 text-xs italic" v-if="emailConsentError">{{ emailConsentError }}</p>
          </div>


        <!-- Botón de Enviar -->
        <div class="mb-4">
          <div class="flex items-center justify-between">
            <button
              class="border-b-4 border-green-800 mt-2 text-sm sm:text-lg block w-full items-center px-8 py-3 bg-green-600 text-white rounded-2xl font-bold hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              type="submit">
              Solicitar oferta en efectivo gratuita →
            </button>
          </div>
        </div>
      </form>
      <div class="mt-2 flex justify-center">
        <p class="text-gray-500 flex items-center">
          <i class="text-green-600 fas fa-shield-alt mr-1 text-sm items-center"></i>
          Seguro, seguro y confidencial
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  props: {
    addressData: {
      type: Object,
      default: () => ({}),
    },
    gclid: {
      type: String,
      default: '',
    },
    quizData: {
      type: Object,
      default: () => ({}),
    },
    lpOfferId: {
      type: String,
      default: '21' // Default value if not provided
    },
    lpCampaignId: {
      type: String,
      default: '67337b507f868' // Default value if not provided
    },
    utmCampaign: {
      type: String,
      default: '',
    },
    utmSource: {
      type: String,
      default: '',
    },
    utmMedium: {
      type: String,
      default: '',
    },
    utmKeyword: {
      type: String,
      default: '',
    },
    utmMatch: {
      type: String,
      default: '',
    },
    utmDevice: {
      type: String,
      default: '',
    },
  },
  name: "ContactForm",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      messageError: "",
      privacyPolicy: false,
      privacyPolicyError: "",
      consentToBMV: false, // BMV checkbox state
      consentToBMVError: '', // BMV error property
      smsConsent: false, // SMS consent checkbox
      smsConsentError: "", // SMS consent error
      emailConsent: false, // Email consent checkbox
      emailConsentError: "", // Email consent error
      isSubmitting: false,
      submitSuccess: false,
      submitError: false,
      quizDataMapping: {
        apiParam1: "on_market",
        apiParam2: "prop_type",
        apiParam3: "beds",
        apiParam4: "reasons_for_sale",
        apiParam5: "speed_of_sale",
        apiParam6: "property_condition",
      },
      phoneHlrStatus: null,  // This will store the active status
      country: "", // New property for the country
      localUtmCampaign: this.utmCampaign,
      localUtmSource: this.utmSource,
      localUtmMedium: this.utmMedium,
      localUtmKeyword: this.utmKeyword,
      localUtmDevice: this.utmDevice,
      localUtmMatch: this.utmMatch,
      localAdId: this.adId,
      localAdSetId: this.adSetId,
      localGclid: this.gclid,
    };
  },
  methods: {
    validateFirstName() {
      if (!this.firstName) {
        this.firstNameError = "El nombre es obligatorio";
      } else {
        this.firstNameError = "";
      }
    },
    validateLastName() {
      if (!this.lastName) {
        this.lastNameError = "El apellido es obligatorio";
      } else {
        this.lastNameError = "";
      }
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email) {
        this.emailError = "El correo electrónico es obligatorio";
      } else if (!emailPattern.test(this.email)) {
        this.emailError = "Dirección de correo electrónico no válida";
      } else {
        this.emailError = "";
      }
    },
    validatePhone() {
    // Updated regex to match US phone numbers with optional +1 and various formats
    const phonePattern = /^(?:\+1\s?)?(\(\d{3}\)\s?|\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;
    if (!this.phone) {
      this.phoneError = "Necesitamos un número válido para hacer una oferta.";
    } else if (!phonePattern.test(this.phone)) {
      this.phoneError = "Número de teléfono no válido. Por favor, ingrese un número válido de EE. UU.";
    } else {
      this.phoneError = "";
    }
  },
    validatePrivacyPolicy() {
      if (!this.privacyPolicy) {
        this.privacyPolicyError = "Debes aceptar la Política de Privacidad";
      } else {
        this.privacyPolicyError = "";
      }
    },
    validateConsentToBMV() {
      if (!this.consentToBMV) {
        this.consentToBMVError = "Debes consentir una oferta por debajo del valor de mercado (BMV).";
      } else {
        this.consentToBMVError = "";
      }
    },
    validateSMSConsent() {
        if (!this.smsConsent) {
          this.smsConsentError = "Debes aceptar recibir mensajes de texto para continuar.";
        } else {
          this.smsConsentError = "";
        }
      },
      validateEmailConsent() {
        if (!this.emailConsent) {
          this.emailConsentError = "Debes aceptar recibir correos electrónicos para continuar.";
        } else {
          this.emailConsentError = "";
        }
      },
    async submitForm() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validatePhone();
      this.validatePrivacyPolicy();
      this.validateConsentToBMV();
      this.validateSMSConsent();
      this.validateEmailConsent();

      if (
        this.firstNameError ||
        this.lastNameError ||
        this.emailError ||
        this.phoneError ||
        this.privacyPolicyError ||
        this.consentToBMVError ||
        this.smsConsentError ||
        this.emailConsentError
      ) {
        return;
      }

      this.isSubmitting = true;

      // Proceed with the form submission
      try {
        const formData = new FormData();
        formData.append("lp_offer_id", this.lpOfferId);
        formData.append("lp_campaign_id", this.lpCampaignId);
        formData.append("site_ref", "rapidsale.com");
        formData.append("first_name", this.firstName);
        formData.append("last_name", this.lastName);
        formData.append("email_address", this.email);
        formData.append("phone_home", this.phone);
        formData.append("accepted_terms", this.privacyPolicy ? "1" : "0");
        formData.append("consent_sms", this.smsConsent ? "1" : "0"); // SMS consent
        formData.append("consent_email", this.emailConsent ? "1" : "0"); // Email consent

        // Append address data if available
        if (this.addressData && this.addressData.street) {
          formData.append("address", this.addressData.street);
          formData.append("city", this.addressData.city);
          formData.append("state", this.addressData.state);
          formData.append("zip_code", this.addressData.zipcode);
          formData.append("country", "US"); // Assuming US addresses
        }

        // Append quizData fields to formData
        for (const key in this.quizData) {
          const apiParamName = this.quizDataMapping[key];
          if (apiParamName) {
            formData.append(apiParamName, this.quizData[key]);
          }
        }

        // Send the form data
        await axios.post("https://leadseeker.leadspediatrack.com/post.do", formData);

        this.submitSuccess = true;
        this.submitError = false;
      } catch (error) {
        this.submitSuccess = false;
        this.submitError = true;
        console.error("Error posting the form:", error);
      }

      this.isSubmitting = false;

      if (this.submitSuccess) {
        // Redirect to the thank you page or display success message
        localStorage.setItem('userEmail', this.email);
        window.location.href = `/es/thank-you?email=${encodeURIComponent(this.email)}`;
      }
    },
  },
};
</script>



<style>
@media (max-width: 640px) {
  .small-device-text {
    font-size: 0.525rem; /* Adjust the value to your preference */
  }
}
</style>
<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
        Política de Privacidad de Rapid Sale, Inc
      </h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        <strong>Fecha de Vigencia:</strong> 10/09/2024
      </p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        <strong>Introducción:</strong> Rapid Sale USA ("nosotros," "nos," o "nuestro") está comprometido con la protección de su privacidad y con garantizar el manejo adecuado de la información personal. Esta Política de Privacidad detalla cómo recopilamos, usamos, divulgamos y protegemos su información personal cuando visita nuestro sitio web <a href="https://www.rapidsale.com" class="underline text-indigo-200">www.rapidsale.com</a> o interactúa con nuestros servicios. Al utilizar nuestro sitio web o servicios, usted acepta la recopilación y el uso de la información de acuerdo con esta política.
      </p>

      <h2 class="text-2xl text-white mt-4">Información que Recopilamos</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Podemos recopilar información personal que usted nos proporcione voluntariamente cuando exprese interés en obtener información sobre nosotros o nuestros productos y servicios, cuando participe en actividades en el sitio web, o de otro modo cuando nos contacte.
      </p>

      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        <strong>Información Personal Recopilada:</strong>
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Información de Identidad:</strong> Nombre completo, estado civil, fecha de nacimiento y género.
        </li>
        <li class="text-xl">
          <strong>Información de Contacto:</strong> Dirección de correo, dirección de correo electrónico y número de teléfono.
        </li>
        <li class="text-xl">
          <strong>Detalles de Propiedad:</strong> Información sobre la propiedad que desea vender.
        </li>
        <li class="text-xl">
          <strong>Información Financiera:</strong> Detalles de la hipoteca, estado financiero u otra información financiera relevante.
        </li>
        <li class="text-xl">
          <strong>Datos de Uso:</strong> Información sobre cómo usa nuestro sitio web y servicios.
        </li>
        <li class="text-xl">
          <strong>Datos Técnicos:</strong> Dirección de Protocolo de Internet (IP), tipo y versión del navegador, configuración de zona horaria, sistema operativo y plataforma.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">Cómo Usamos Su Información</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Usamos la información personal recopilada a través de nuestro sitio web para una variedad de propósitos comerciales descritos a continuación:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Para Proporcionar Servicios:</strong> Procesar sus solicitudes, transacciones y consultas.
        </li>
        <li class="text-xl">
          <strong>Para Enviar Información Administrativa:</strong> Información sobre nuestros servicios y cambios en nuestros términos, condiciones y políticas.
        </li>
        <li class="text-xl">
          <strong>Para Proteger Nuestros Servicios:</strong> Para monitoreo y prevención de fraude.
        </li>
        <li class="text-xl">
          <strong>Para Hacer Cumplir Nuestros Términos y Políticas:</strong> Para el cumplimiento legal y regulatorio.
        </li>
        <li class="text-xl">
          <strong>Para Responder a Solicitudes Legales:</strong> Cumplir con leyes aplicables, órdenes judiciales y regulaciones gubernamentales.
        </li>
        <li class="text-xl">
          <strong>Para Otros Propósitos Comerciales:</strong> Análisis de datos, identificación de tendencias de uso y mejora de nuestro sitio web y servicios.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">Divulgación de Su Información</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Podemos compartir su información con terceros en ciertas situaciones:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Proveedores de Servicios:</strong> Podemos compartir su información con proveedores, contratistas o agentes de terceros que realicen servicios para nosotros o en nuestro nombre.
        </li>
        <li class="text-xl">
          <strong>Obligaciones Legales:</strong> Si se nos requiere hacerlo por ley o en respuesta a solicitudes válidas de autoridades públicas.
        </li>
        <li class="text-xl">
          <strong>Transferencias de Negocios:</strong> En relación con, o durante negociaciones de, cualquier fusión, venta de activos de la empresa, financiación o adquisición de toda o parte de nuestro negocio.
        </li>
        <li class="text-xl">
          <strong>Con Su Consentimiento:</strong> Podemos divulgar su información personal para cualquier otro propósito con su consentimiento.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">Seguridad de Datos</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Implementamos medidas razonables de seguridad administrativa, técnica y física para proteger su información personal. Sin embargo, ninguna medida de seguridad es 100% segura, y no podemos garantizar la seguridad absoluta de sus datos.
      </p>

      <h2 class="text-2xl text-white mt-4">Retención de Datos</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Retendremos su información personal solo durante el tiempo necesario para los fines establecidos en esta Política de Privacidad y en la medida necesaria para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestras políticas.
      </p>

      <h2 class="text-2xl text-white mt-4">Sus Derechos de Privacidad</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Dependiendo de su ubicación, puede tener los siguientes derechos:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Acceso:</strong> Solicitar información sobre los datos personales que tenemos sobre usted.
        </li>
        <li class="text-xl">
          <strong>Corrección:</strong> Solicitar que corrijamos o actualicemos su información personal.
        </li>
        <li class="text-xl">
          <strong>Eliminación:</strong> Solicitar que eliminemos su información personal.
        </li>
        <li class="text-xl">
          <strong>Exclusión:</strong> Excluirse de las comunicaciones de marketing.
        </li>
        <li class="text-xl">
          <strong>No Discriminación:</strong> No le discriminaremos por ejercer cualquiera de sus derechos de privacidad.
        </li>
      </ul>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Para ejercer estos derechos, por favor contáctenos en <a href="mailto:privacy@rapidsale.com" class="underline text-indigo-200">privacy@rapidsale.com</a>.
      </p>

      <h2 class="text-2xl text-white mt-4">Privacidad Infantil</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Nuestros servicios no están destinados a individuos menores de 18 años. No recopilamos conscientemente información personal de niños menores de 18 años. Si se da cuenta de que un niño nos ha proporcionado información personal, por favor contáctenos.
      </p>

      <h2 class="text-2xl text-white mt-4">Cookies y Tecnologías de Seguimiento</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Podemos usar cookies y tecnologías de seguimiento similares para acceder o almacenar información. Puede configurar su navegador para rechazar todas o algunas cookies del navegador o para alertarlo cuando los sitios web configuren o accedan a cookies.
      </p>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Para obtener información detallada sobre las cookies que usamos y los fines para los cuales las usamos, consulte nuestra <a href="#" class="underline text-indigo-200">Política de Cookies</a>.
      </p>

      <h2 class="text-2xl text-white mt-4">Sitios Web de Terceros</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Nuestro sitio web puede contener enlaces a sitios web de terceros. No tenemos control sobre, y no somos responsables del contenido o las prácticas de privacidad de dichos sitios web. Le recomendamos que revise las políticas de privacidad de los sitios web de terceros que visite.
      </p>

      <h2 class="text-2xl text-white mt-4">Actualizaciones de Esta Política de Privacidad</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Podemos actualizar esta Política de Privacidad de vez en cuando. La versión actualizada se indicará con una "Fecha de Vigencia" actualizada y será efectiva tan pronto como esté disponible. Le recomendamos que revise esta Política de Privacidad periódicamente.
      </p>

      <h2 class="text-2xl text-white mt-4">Contáctenos</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Si tiene preguntas o comentarios sobre esta Política de Privacidad, por favor contáctenos en:
      </p>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        <strong>Rapid Sale, Inc</strong><br />
        1 North Clematis Street, Suite 550<br />
        West Palm Beach, FL 33401<br />
        <strong>Correo Electrónico:</strong> <a href="mailto:privacy@rapidsale.com" class="underline text-indigo-200">privacy@rapidsale.com</a><br />
        <strong>Teléfono:</strong> <a href="tel:18001234567" class="underline text-indigo-200">1-800-123-4567</a>
      </p>
    </div>
  </div>
</template>


<script>
  
export default {
    name: 'PrivacyPolicy',
    metaInfo() {
      return {
        title: 'Política de Privacidad | Rapid Sale (USA)',
        meta: [
          {
            name: 'description',
            content: 'Política de Privacidad para Rapid Sale',
          },
          {
            name: 'keywords',
            content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo',
          },
        ],
        link: [
          { rel: 'canonical', href: 'https://rapid-sale.com/es/privacy-policy' }
        ]
      };
    },
};
</script>

<template>
  <div></div>
</template>

<script>
export default {
  name: 'ContenidoVacio',
  metaInfo() {
    return {
      title: 'Oferta Rápida de Efectivo - Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Rapid Sale se especializa en la compra de propiedades por efectivo, rápido y con 100% sin comisiones. Obtén una oferta en efectivo gratuita hoy.',
        },
        {
          name: 'keywords',
          content: 'oferta en efectivo por propiedad, compramos cualquier casa, venta rápida de casas, compradores de casas en efectivo, vender casa rápido',
        },
      ],
    };
  },
};
</script>


<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
        Privacy Policy of Rapid Sale, Inc
      </h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        <strong>Effective Date:</strong> 10/09/2024
      </p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">
        <strong>Introduction:</strong> Rapid Sale USA ("we," "us," or "our") is committed to protecting your privacy and ensuring the proper handling of personal information. This Privacy Policy details how we collect, use, disclose, and safeguard your personal information when you visit our website <a href="https://www.rapidsale.com" class="underline text-indigo-200">www.rapidsale.com</a> or engage with our services. By using our website or services, you agree to the collection and use of information in accordance with this policy.
      </p>

      <h2 class="text-2xl text-white mt-4">Information We Collect</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We may collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services, when you participate in activities on the website, or otherwise when you contact us.
      </p>

      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        <strong>Personal Information Collected:</strong>
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Identity Information:</strong> Full name, marital status, date of birth, and gender.
        </li>
        <li class="text-xl">
          <strong>Contact Information:</strong> Mailing address, email address, and telephone number.
        </li>
        <li class="text-xl">
          <strong>Property Details:</strong> Information about the property you wish to sell.
        </li>
        <li class="text-xl">
          <strong>Financial Information:</strong> Mortgage details, financial status, or other relevant financial information.
        </li>
        <li class="text-xl">
          <strong>Usage Data:</strong> Information about how you use our website and services.
        </li>
        <li class="text-xl">
          <strong>Technical Data:</strong> Internet Protocol (IP) address, browser type and version, time zone setting, operating system, and platform.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">How We Use Your Information</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We use personal information collected via our website for a variety of business purposes described below:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>To Provide Services:</strong> Processing your requests, transactions, and inquiries.
        </li>
        <li class="text-xl">
          <strong>To Send Administrative Information:</strong> Information regarding our services and changes to our terms, conditions, and policies.
        </li>
        <li class="text-xl">
          <strong>To Protect Our Services:</strong> For fraud monitoring and prevention.
        </li>
        <li class="text-xl">
          <strong>To Enforce Our Terms and Policies:</strong> For legal and regulatory compliance.
        </li>
        <li class="text-xl">
          <strong>To Respond to Legal Requests:</strong> Comply with applicable laws, court orders, and governmental regulations.
        </li>
        <li class="text-xl">
          <strong>For Other Business Purposes:</strong> Data analysis, identifying usage trends, and improving our website and services.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">Disclosure of Your Information</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We may share your information with third parties in certain situations:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Service Providers:</strong> We may share your information with third-party vendors, contractors, or agents who perform services for us or on our behalf.
        </li>
        <li class="text-xl">
          <strong>Legal Obligations:</strong> If required to do so by law or in response to valid requests by public authorities.
        </li>
        <li class="text-xl">
          <strong>Business Transfers:</strong> In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business.
        </li>
        <li class="text-xl">
          <strong>With Your Consent:</strong> We may disclose your personal information for any other purpose with your consent.
        </li>
      </ul>

      <h2 class="text-2xl text-white mt-4">Data Security</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We implement reasonable administrative, technical, and physical security measures to protect your personal information. However, no security measures are 100% secure, and we cannot guarantee absolute security of your data.
      </p>

      <h2 class="text-2xl text-white mt-4">Data Retention</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy and to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
      </p>

      <h2 class="text-2xl text-white mt-4">Your Privacy Rights</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Depending on your location, you may have the following rights:
      </p>
      <ul class="list-disc list-inside space-y-2 py-2 pl-5 text-indigo-100">
        <li class="text-xl">
          <strong>Access:</strong> Request information about the personal data we hold about you.
        </li>
        <li class="text-xl">
          <strong>Correction:</strong> Request that we correct or update your personal information.
        </li>
        <li class="text-xl">
          <strong>Deletion:</strong> Request that we delete your personal information.
        </li>
        <li class="text-xl">
          <strong>Opt-Out:</strong> Opt-out of marketing communications.
        </li>
        <li class="text-xl">
          <strong>Non-Discrimination:</strong> We will not discriminate against you for exercising any of your privacy rights.
        </li>
      </ul>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        To exercise these rights, please contact us at <a href="mailto:privacy@rapidsale.com" class="underline text-indigo-200">privacy@rapidsale.com</a>.
      </p>

      <h2 class="text-2xl text-white mt-4">Children's Privacy</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us.
      </p>

      <h2 class="text-2xl text-white mt-4">Cookies and Tracking Technologies</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We may use cookies and similar tracking technologies to access or store information. You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies.
      </p>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        For detailed information on the cookies we use and the purposes for which we use them, please see our <a href="#" class="underline text-indigo-200">Cookie Policy</a>.
      </p>

      <h2 class="text-2xl text-white mt-4">Third-Party Websites</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        Our website may contain links to third-party websites. We have no control over, and are not responsible for, the content or privacy practices of such websites. We encourage you to review the privacy policies of any third-party sites you visit.
      </p>

      <h2 class="text-2xl text-white mt-4">Updates to This Privacy Policy</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Effective Date" and will be effective as soon as it is accessible. We encourage you to review this Privacy Policy periodically.
      </p>

      <h2 class="text-2xl text-white mt-4">Contact Us</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        If you have questions or comments about this Privacy Policy, please contact us at:
      </p>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">
        <strong>Rapid Sale, Inc</strong><br />
        1 North Clematis Street, Suite 550<br />
        West Palm Beach, FL 33401<br />
        <strong>Email:</strong> <a href="mailto:privacy@rapidsale.com" class="underline text-indigo-200">privacy@rapidsale.com</a><br />
        <strong>Phone:</strong> <a href="tel:18001234567" class="underline text-indigo-200">1-800-123-4567</a>
      </p>
    </div>
  </div>
</template>



      
      <script>
    
      
      export default {
          name: 'PrivacyPolicy',
          metaInfo() {
            return {
              title: 'Privacy Policy | Rapid Sale (USA)',
              meta: [
                {
                  name: 'description',
                  content: 'Privacy Policy for Rapid Sale',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
              ],
              link: [
                { rel: 'canonical', href: 'https://rapid-sale.com/privacy-policy' }
              ]
            };
          },
        };
      </script>
      
    
      
<template>
  <header class="bg-white shadow-md py-3 relative z-50 px-1 md:px-0">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo and Reviews Badge Container -->
      <div class="flex items-center">
        <!-- Logo -->
        <a href="/es/" class="mr-2">
          <img src="@/assets/rapidsale-logo.svg" alt="Rapid Sale Logo" class="h-10 md:h-11">
        </a>

        <!-- USA Flag Logo with Left Margin -->
        <img src="@/assets/logo-flag-usa.png" alt="Bandera de EE.UU." class="h-10 md:h-11 ml-2">
      </div>

            <!-- Language Dropdown -->
            <div class="relative pl-2 pr-2">
        <select class="bg-white border text-gray-800 rounded-md p-2 " @change="changeLanguage">
          <option value="es">Español</option>
          <option value="en">English</option>
        </select>
      </div>

      <nav class="hidden md:flex items-center space-x-6 md:relative md:bg-transparent md:shadow-none" v-show="(!isMobile || menuOpen) && !$route.path.startsWith('/articles/')">
        <router-link to="/es/" class="text-gray-800 hover:text-blue-600">INICIO</router-link>
        <router-link to="/es/how-we-work" class="text-gray-800 hover:text-blue-600">CÓMO FUNCIONA</router-link>
        <router-link to="/es/about-us" class="text-gray-800 hover:text-blue-600">SOBRE NOSOTROS</router-link>
        <router-link to="/es/contact-us" class="text-gray-800 hover:text-blue-600">CONTÁCTANOS</router-link>
      </nav>

      <div @click.prevent="toggleMenu" class="md:hidden focus:outline-none cursor-pointer" v-if="!$route.path.startsWith('/articles/')">
        <i v-if="!menuOpen" class="fas fa-bars text-xl text-gray-800"></i>
        <i v-else class="fas fa-times text-xl text-gray-800"></i>
      </div>

      <!-- Phone Number and Icon Container -->
      <div class="flex items-center order-3 ml-4">
        <!-- Phone Icon and Number Link -->
        <a href="tel:+18136423959" class="bg-green-500 text-white px-3 py-1 rounded-md flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 mr-2" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" clip-rule="evenodd" />
          </svg>
          <div class="hidden md:flex flex-col">
            <div class="font-semibold">+1 813-642-3959</div>
            <div class="text-xs">¡Habla con nosotros!</div>
          </div>
        </a>
      </div>
    </div>

    <div class="mobile-menu md:hidden" v-show="menuOpen">
      <nav class="bg-white shadow-md py-3 mt-1">
        <div class="container mx-auto flex flex-col space-y-3">
          <a href="/es/" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">INICIO</a>
          <a href="/es/how-we-work" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">CÓMO FUNCIONA</a>
          <a href="/es/about-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">SOBRE NOSOTROS</a>
          <a href="/es/contact-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">CONTÁCTANOS</a>
        </div>
      </nav>
    </div>
  </header>

  <div class="bg-black py-2 text-center">
    <div class="inline-flex items-center">
      <!-- Five Gold Stars -->
      <span class="text-yellow-500 text-2xl">
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
      </span>
      <!-- Reviews Text -->
      <span class="text-white text-lg font-semibold ml-3">
        Más de 135 Reseñas
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      menuOpen: false,
      isMobile: false,
    };
  },
  methods: {
    changeLanguage(event) {
      const selectedLanguage = event.target.value;
      if (selectedLanguage === "es") {
        this.$router.push("/es/").then(() => {
          window.location.reload(); // Force page reload
        });
      } else {
        this.$router.push("/").then(() => {
          window.location.reload(); // Force page reload
        });
      }
    },
    toggleMenu() {
      if (this.isMobile) {
        this.menuOpen = !this.menuOpen;
      }
    },
    loadReviewWidgetScript() {
      const script = document.createElement('script');
      script.src = '//widget.reviews.io/modern-widgets/rating-bar.js';
      script.async = true;
      document.body.appendChild(script);
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 768;
    });

    this.loadReviewWidgetScript();
  },
};
</script>

<style>
/* Style for the stars and text */
.text-yellow-500 {
  color: #ffc107; /* Gold color for the stars */
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.ml-3 {
  margin-left: 0.75rem;
}

.bg-black {
  background-color: #000;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>




<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover" src="@/assets/rapid-staff-image2.jpg" alt="Rapid Sale USA Team">
      <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <!-- Add the black box styling here -->
      <div class="bg-black bg-opacity-82 rounded-lg p-6">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">About Us</h1>

        <h2 class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold tracking-tight text-white">Bringing 15 Years of Expertise to the USA</h2>

        <p class="mt-6 max-w-3xl text-xl text-white">
          At Rapid Sale USA, your peace of mind is our top priority. With over 15 years of experience as top property cash buyers in the UK, we are excited to expand our services to the United States, now based in Miami, Florida. We are committed to offering a service that's not just professional and discreet, but also friendly and understanding.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          Throughout our journey, we've been dedicated to delivering tailored solutions to homeowners, helping them sell their homes quickly and with minimal stress. Whether facing urgent financial demands, coping with foreclosure notices, or simply wanting a swift sale to bypass the complexities of the traditional market, we've managed it all. Our commitment to excellence is reflected in the positive feedback from our clients.
        </p>

        <p class="mt-6 max-w-3xl text-xl text-white">
          By bringing our code of ethics and exceptional customer service to the United States, we aim to revolutionize the American real estate market and help people sell fast with little stress. Every homeowner's situation is unique, and we honor that by going above and beyond. Need help with moving or storage? Seeking advice on the next steps? We're here every step of the way, ensuring your property transition is as stress-free as possible. At Rapid Sale USA, we don't just handle transactions; we transform challenging moves into positive new beginnings.
        </p>
        
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
          Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>


<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

export default {
  name: 'AboutUs',
  metaInfo() {
    return {
      title: 'About Us | Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Welcome to Rapid Sale the USA\'s Premier Property Cash Buyers',
        },
        {
          name: 'keywords',
          content: 'rapid sale, rapidsale, property cash offer, we buy any home, fast house sale, cash house buyers',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.com/about-us' }
      ]
    };
  },
};
</script>

  

  